import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import swal from 'sweetalert';
import Modal from 'react-modal';

import clientData from './inc/clientData';
import config from './inc/config';
import {randomUUID} from './inc/compat';
import {getCookie, setCookie} from './inc/cookies';

import AnonymousFrame from './frame/AnonymousFrame';
import UserFrame from './frame/UserFrame';

import CreditRequestHistories from './pages/CreditRequestHistories';
import CreditProviders from './pages/CreditProviders';
import CustomerService from './pages/CustomerService';
import CreditRequestDetail from './pages/CreditRequestDetail';
import CreditRequests from './pages/CreditRequests';
import NewCreditApply from './pages/NewCreditApply';
import Welcome from './pages/Welcome';

import '../src/assets/css/index.css';
import CreditCardPayment from './pages/CreditCardPayment';
import CreditCardPaymentRequests from './pages/CreditCardPaymentRequests';
import CreditCardRequests from './pages/CreditCardRequests';
import CreditCardPaymentDetail from './pages/CreditCardPaymentDetail';

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    console.log('Index: componentDidMount()');
    this.init();
  }

  componentWillUnmount() {
    console.log('Index: componentWillUnmount()');
  }

  init() {
    clientData.deviceId = getCookie('deviceId', randomUUID());
    clientData.sessionHash = getCookie('sessionHash', '');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({
        deviceId: clientData.deviceId,
        deviceManufacturer: '',
        deviceModel: '',
        osLanguage: '',
        osVersion: '',
        versionCode: '',
        versionName: '',
        platform: 'web',
        token: '',
        timezone: '+03:00',
        userAgent: navigator.userAgent
      })
    };

    fetch(config.restApi + 'init', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          clientData.sessionHash = responseData.sessionHash;
          clientData.sessionType = responseData.sessionType;

          setCookie('deviceId', clientData.deviceId, 31536000);
          setCookie('sessionType', clientData.sessionType, 31536000);

          clientData.sessionType === 'anonymous' && setCookie('sessionHash', clientData.sessionHash, 31536000);

          this.start(responseData);
        break;
        default:
          swal({dangerMode: true, icon: 'error', title: 'Hay Aksi', text: responseData.message, buttons: 'Yeniden Dene'}).then(() => this.init());
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Yükle'}).then(() => this.init()));
  }

  start(_responseData) {
    this.setState({isLoading: false});
  }

  render() {
    clientData.sessionType = getCookie('sessionType', '');

    return this.state.isLoading ?
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      clientData.sessionType === 'member' ?
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<UserFrame><Welcome/></UserFrame>} />
          <Route path="/creditCardPayment" element={<UserFrame><CreditCardPayment /></UserFrame>} />
          <Route path="/creditCardPaymentDetail" element={<UserFrame><CreditCardPaymentDetail /></UserFrame>} />
          <Route path="/creditCardPaymentRequests" element={<UserFrame><CreditCardPaymentRequests /></UserFrame>} />
          <Route path="/creditCardRequests" element={<UserFrame><CreditCardRequests /></UserFrame>} />
          <Route path="/creditRequestHistories" element={<UserFrame><CreditRequestHistories /></UserFrame>} />
          <Route path="/creditProviders" element={<UserFrame><CreditProviders /></UserFrame>} />
          <Route path="/customerService" element={<UserFrame><CustomerService /></UserFrame>} />
          <Route path="/newCreditApply" element={<UserFrame><NewCreditApply /></UserFrame>} />
          <Route path="/creditRequests" element={<UserFrame><CreditRequests /></UserFrame>} />
          <Route path="/creditRequestDetail" element={<UserFrame><CreditRequestDetail /></UserFrame>} />
        </Routes>
      </BrowserRouter>
      :
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AnonymousFrame />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
  }
}

const appElement = document.getElementById('root');
Modal.setAppElement(appElement);
ReactDOM.createRoot(appElement).render(<Index />);