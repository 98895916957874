import React from 'react';
import {useNavigate, Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

import Images from '../utils/Images';

import clientData from '../inc/clientData';
import config from '../inc/config';
import {isoToDateTime, priceFormat} from '../inc/helper';
import { getCookie } from '../inc/cookies';

class CreditCardRequestModule extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,

      creditCardPayments: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    this.getCreditCardRequests(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  filteredCreditRequests(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + sessionHash}
  };

  const startIndex = 0;
  const endIndex = startIndex + this.state.limit;

  fetch(config.restApi + `creditCardPayments/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentCreditCardPayments = responseData.creditCardPayments.slice(startIndex, endIndex);
          this.setState({isLoading: false, creditCardPayments: currentCreditCardPayments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getCreditCardRequests(currentPage) {
      const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `creditCardPayments/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentCreditCardPayments = responseData.creditCardPayments.slice(startIndex, endIndex);
          this.setState({isLoading: false, creditCardPayments: currentCreditCardPayments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.filteredCreditRequests(this.state.currentPage))
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  getCreditStatusName(paymentStatus) {
    if(paymentStatus === 1){
      return <strong className="font-bold text-warning">İşleme Alındı</strong>
    } else if(paymentStatus === 2){
      return <strong className="font-bold text-green">Başarılı</strong>
    } else if(paymentStatus === 3){
      return <strong className="font-bold text-danger">Başarısız</strong>
    }

    return '-'
  }

  render() {
  return this.state.isLoading ?
    <div className="ibox-content sk-loading position-static">
      <div className="sk-spinner sk-spinner-fading-circle">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
      </div>
    </div>
    :
    <div className="row custom-container">
      <div className="col-12">
        <div className="ibox">
          <div className="ibox-title">
            <h5 className="text-black">Kredi Başvurularım</h5>
          </div>
          <div className="table-responsive-lg">
            <table className="table table-bordered text-center">
              <thead>
                <tr className="text-black">
                  <th>İlan Görseli</th>
                  <th>İlan Başlığı</th>
                  <th>Başvuru Sahibi</th>
                  <th>İşlemi Yapan</th>
                  <th>İşlem Tutarı</th>
                  <th>Ödeme Tarihi</th>
                  <th>Ödeme Durumu</th>
                  <th>Sonuç</th>
                  <th>Detay</th>
                </tr>
              </thead>
              <tbody>
              {this.state.creditCardPayments.map((creditCardPaymentInfo) =>
                <tr key={creditCardPaymentInfo.id}>
                  <td className="align-middle">
                    {creditCardPaymentInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${creditCardPaymentInfo.photos[0].path}`}/>
                    : <i className="fa fa-camera d-flex justify-content-center align-items-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{creditCardPaymentInfo.advertId}</strong>
                  </td>
                  <td className="align-middle">{creditCardPaymentInfo.advertTitle}</td>
                  <td className="align-middle">{creditCardPaymentInfo.memberFullName}</td>
                  <td className="align-middle">{creditCardPaymentInfo.addedFullName}</td>
                  <td className="align-middle">{priceFormat(creditCardPaymentInfo.amount)}</td>
                  <td className="align-middle">{isoToDateTime(creditCardPaymentInfo.paymentDate)}</td>
                  <td className="align-middle">{this.getCreditStatusName(creditCardPaymentInfo.paymentStatusId)}</td>
                  <td className="align-middle">{creditCardPaymentInfo.paymentStatusId === 3 ? <strong className="font-bold text-danger">Başarısız</strong> : creditCardPaymentInfo.paymentStatusId !== 2 ? <strong className="font-bold text-warning">Beklemede</strong> : creditCardPaymentInfo.cancelledBy ? <strong className="font-bold text-danger">İptal Edildi</strong> : <strong className="font-bold text-green">Başarılı</strong>}</td>
                  <td className="align-middle"><Link className="btn btn-outline btn-green text-center mb-1" to={'/creditCardPaymentDetail?id=' + creditCardPaymentInfo.id} title="Başvuru Detayları"><i className="fa fa-eye"></i></Link></td>
                </tr>
                )}
              {this.state.creditCardPayments.length === 0 && <tr><td className="align-middle p-3 text-black" colSpan="9">Kredi Kartı Ödeme Listesi Bulunamadı !</td></tr>}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={"Geri"}
            nextLabel={"İleri"}
            breakLabel={"..."}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={1}
            onPageChange={page => this.handlePageClick(page)}
            pageRangeDisplayed={2}
            containerClassName={'pagination'}
            activeClassName={'activePage'}
          />
        </div>
      </div>
    </div>
  }
}

export default function CreditCardRequests(props) {
  return (
    <CreditCardRequestModule navigate={useNavigate()} />
  )
}