import React from 'react';
import {Link,useNavigate, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';
import Images from '../utils/Images';
import clientData from '../inc/clientData'
import config from '../inc/config';

import {CustomWidgetPrepare} from '../components/CustomWidget';
import {isoToDateTime, priceFormat} from '../inc/helper';
import CreditCardPaymentButtonModal from '../components/CreditCardPaymentButtonModal';

class CreditCardPaymentRequestsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      advertInfo: {},
      amount: 0,
      iban: "",
      ibanHolderName: "",
      buttonDisabled: true,
      isOpenAddressModal: false,
      ibanError: '',
      storeInfo: {}
    }
  }

  componentDidMount(){
    this.getAdvertDetail();
    window.scrollTo(0, 0);
  }

  getAdvertDetail() {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: errorMessages[0],
            buttons: 'Tamam'
          });
        }
      }
    }

    fetch(config.restApi + 'advert/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({advertInfo: responseData.advertInfo});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'storeInfo/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({storeInfo: responseData.storeInfo});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  advertStatus(id) {
    if(id === 1) {
      return 'Onay Bekliyor'
    } else if(id === 2) {
      return 'Yayında'
    } else if(id === 3) {
      return 'Reddedildi'
    }
    return 'Pasif'
  }

  saveAmountOnClick() {

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({amount: parseInt(this.state.amount), advertId: parseInt(this.props.searchParams.get('id'))})
    };

    fetch(config.restApi + 'creditCardPayment/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          window.location.href = responseData.url;
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleIbanChange = (event) => {
    const rawInput = event.target.value.replace(/\D/g, '');
    const rawIban = `TR${rawInput}`;
    const formattedIban = rawIban.replace(/(.{4})/g, '$1 ').trim();
    this.setState({ iban: formattedIban, ibanError: '' });
  };
  
  handleIbanBlur = () => {
    if (this.state.iban.replace(/\s+/g, '').length === 32) {
      this.setState({ ibanError: '' });
    } else {
      this.setState({ ibanError: 'İban geçersiz. Lütfen ibanı kontrol edin!' });
    }
  };

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="col-lg-12">
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-black d-flex justify-content-between pr-4">
                <h4>Kredi Kartı İşlemleri</h4>
              </div>
              <div className="ibox-content min-height">
                <div className="row mb-4">
                  <div className="col">
                    <div className="table-responsive-lg">
                      <table className="table table-bordered text-center">
                        <thead>
                          <tr className="text-black">
                            <th>İlan No</th>
                            <th>İlan Sahibi</th>
                            <th>İlan Başlığı</th>
                            <th>Fiyat</th>
                            <th>İlan Tarihi</th>
                            <th>Bölge</th>
                            <th>İlan Durumu</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          <tr>
                            <td className="align-middle">
                              {this.state.advertInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${this.state.advertInfo.photos[0].path}`}/>
                              : <i className="fa fa-camera d-flex justify-content-center align-items-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{this.state.advertInfo.id}</strong>
                            </td>
                            <td className="align-middle">{this.state.advertInfo.memberFullName}</td>
                            <td className="align-middle">{this.state.advertInfo.languages.find((languageInfo) => languageInfo.language === 'tr').title}</td>
                            <td className="align-middle">{priceFormat(this.state.advertInfo.price)} {this.state.advertInfo.currencySymbol}</td>
                            <td className="align-middle">{isoToDateTime(this.state.advertInfo.addedDate)}</td>
                            <td className="align-middle">{this.state.advertInfo.cityName + '/' + this.state.advertInfo.townName}</td>
                            <td className="align-middle">{this.advertStatus(this.state.advertInfo.advertStatus)}</td>
                          </tr>
                        }
                        {this.state.advertInfo.length === 0 && <tr><td className="align-middle p-3 text-black" colSpan="7">İlan Bilgisi Bulunamadı !</td></tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <strong>İban Sahibi Adı Soyadı<strong className="text-danger ml-1">*</strong> <small>(Alıcı hesap bilgilerinde bir hata olduğunu düşünüyorsanız, müşteri temsilciniz ile iletişime geçin.)</small></strong>
                  <div className="input-group">
                    <input className="form-control form-input" readOnly value={this.state.storeInfo.bankAccountHolder || '-'} />
                  </div>
                  <div className="mt-2">
                    <strong>İban<strong className="text-danger ml-1">*</strong></strong>
                    <div className="input-group">
                    <input className="form-control form-input" readOnly value={this.state.storeInfo.bankIban || '-'} />
                    </div>
                    {this.state.storeInfo.bankIban.length !== 32 && <span className="text-danger">* İban numaranız hatalı olduğu için işleme devam edilemiyor.</span>}
                  </div>
                  <div className="mt-2">
                    <strong>Kredi Kartı Ödeme Tutarını Giriniz<strong className="text-danger ml-1">*</strong></strong>
                    <div className="input-group">
                      <input className="form-control form-input" onChange={event => this.setState({amount: event.target.value})} placeholder="Kredi Kartı Ödeme Tutarını Giriniz" type="number" value={this.state.amount} />
                    </div>
                  </div>
                  <div className="mt-2">
                    <strong className="mt-3">Kredi Kartı Sözleşmesi<strong className="text-danger ml-1">*</strong></strong>
                    <div className="input-group">
                      <a href='https://file.satisgaranti.com/uploads/agreements/kredi_karti_ile_odeme_talimatnamesi.docx' onClick={() => this.setState({buttonDisabled: false})}>Kredi Kartı İle Ödeme Talimatnamesi</a>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end text-black mt-2">
                    <button className="btn border bg-darkblue text-white" disabled={this.state.storeInfo.bankIban.length !== 32} onClick={() => this.state.buttonDisabled === true ? this.setState({isOpenAddressModal: true}) : this.setState({isOpenAddressModal: true}) /* this.saveAmountOnClick() */} title="Ödemeyi Başlat">Ödemeyi Başlat <i className="fa fa-credit-card"></i></button>
                  </div>
                  <CreditCardPaymentButtonModal isOpen={this.state.isOpenAddressModal} onRequestClose={() => this.setState({isOpenAddressModal: false})} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CreditCardPaymentRequests(props) {
  return (
    <CreditCardPaymentRequestsModule navigate={useNavigate()} searchParams={useSearchParams()[0]}></CreditCardPaymentRequestsModule>
  )
}