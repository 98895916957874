import React from 'react';
import {Link,useNavigate, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';

import clientData from '../inc/clientData';
import config from '../inc/config';
import {getCreditStatusName, isoToDateTime, priceFormat, slugify} from '../inc/helper';
import { getCookie } from '../inc/cookies';

class CreditCardPaymentDetailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      uploadLoading: false,

      creditCardPaymentInfo: {}
    };
  }

  componentDidMount() {
    this.getMyCreditDetail();
    window.scrollTo(0, 0);
  }

  getMyCreditDetail() {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    fetch(config.restApi + 'creditCardPayment/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => responseData.status === 200 ? this.setState({isLoading: false, creditCardPaymentInfo: responseData.creditCardPaymentInfo}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/')), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getMyCreditDetail()));
  }

  deleteFileOnClick(uploadId) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'kredi işlem dosyasını silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash},
        };

        fetch(config.restApi + 'creditCardPaymentFileUpload/tr/' + uploadId, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'kredi işlem dosyası başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getMyCreditDetail());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveFileUpload = (index = 0) => {
    let sessionHash = getCookie('sessionHash');
    const formData = new FormData();
  
    for (let i = 0; i < this.state.files.length; i++) {
      formData.append('files', this.state.files[i]);
    }
  
    const fileCount = formData.getAll('files').length;
    const singleFileData = new FormData();
    singleFileData.append('files', formData.getAll('files')[index]);
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + sessionHash },
      body: singleFileData,
    };
  
    this.setState({ uploadLoading: true });
  
    fetch(config.restApi + 'creditCardPaymentFileUpload/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then((response) => response.json())
      .then(responseData => {
        const progressPercentage = ((index + 1) / fileCount) * 100;
        document.querySelector('.progress-bar').style.width = `${progressPercentage}%`;
        document.querySelector('.progress-bar').innerText = `%${Math.round(progressPercentage)} Tamamlandı`;
  
        switch (responseData.status) {
          case 200:
            if (index === fileCount - 1) {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Başarılı',
                text: 'Evrak kredi detayına başarıyla eklendi.',
                buttons: 'Tamam'
              }).then(() => {this.setState({ isLoading: true }, () => {this.getMyCreditDetail(); this.setState({ files: '' }); this.setState({ isLoading: false, uploadLoading: false });});});
            } else {
              this.saveFileUpload(index + 1);
            }
            break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => {
              this.getMyCreditDetail();
              this.setState({ uploadLoading: false });
            });
            break;
        }
      })
      .catch(() =>
        swal({
          dangerMode: true,
          icon: 'error',
          title: 'Bağlantı Hatası',
          text: 'Lütfen internet bağlantınızı kontrol ediniz.',
          buttons: 'Tamam',
        }).then(() => this.setState({ uploadLoading: false })));
  };

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

	getCreditStatusName(paymentStatus) {
    if(paymentStatus === 1){
      return <strong className="font-bold text-warning">İşleme Alındı</strong>
    } else if(paymentStatus === 2){
      return <strong className="font-bold text-green">Başarılı</strong>
    } else if(paymentStatus === 3){
      return <strong className="font-bold text-danger">Başarısız</strong>
    }

    return '-'
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      : this.state.uploadLoading ?
      <div>
        <div className="d-flex justify-content-center"><strong>Dosyalar yüklenene kadar lütfen sayfada bekleyiniz!</strong></div>
        <div className="progress">
          <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      :
      <div className="col-lg-12">
        <div className="row">
          <div className="col">
            {this.state.creditCardPaymentInfo.files.length === 0 ? <div class="alert alert-danger"> Kredi işlemlerini tamamlamak için <a class="alert-link" href="#evrak">evrak yükleyiniz</a>.</div>: ''}
            <div className="ibox">
              <div className="ibox-title text-black d-flex justify-content-between pr-2">
                <h4>Başvuru Bilgilerim</h4>
                <div className="d-flex">
                  <Link className="btn border bg-darkblue text-white" to={'/creditCardRequests'}><i className="fa fa-arrow-left"></i> Geri Dön</Link>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col">
                    <h4 className="text-green">BAŞVURU DETAYLARI</h4>
                    <div className="hr-line-dashed mt-0"></div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">Başvuru No</h4>
                          {this.state.creditCardPaymentInfo.id ? <span>{this.state.creditCardPaymentInfo.id}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">İlan No</h4>
                          {this.state.creditCardPaymentInfo.advertId ? <span>{this.state.creditCardPaymentInfo.advertId}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">İlan Başlığı</h4>
                          {this.state.creditCardPaymentInfo.advertTitle ? <span>{this.state.creditCardPaymentInfo.advertTitle}</span> : '-'}
                        </div>
                      </div>
											<div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">Başvuru Sahibi</h4>
                          {this.state.creditCardPaymentInfo.memberFullName ? <span>{this.state.creditCardPaymentInfo.memberFullName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">İban Sahibi</h4>
                          {this.state.creditCardPaymentInfo.ibanHolderName ? <span>{this.state.creditCardPaymentInfo.ibanHolderName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">İban No</h4>
                          {this.state.creditCardPaymentInfo.iban ? <span>{this.state.creditCardPaymentInfo.iban}</span> : '-'}
                        </div>
                      </div>
											<div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">Ödeme Durumu</h4>
                          {this.state.creditCardPaymentInfo.paymentStatusId ? <span>{this.getCreditStatusName(this.state.creditCardPaymentInfo.paymentStatusId)}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">Fiyat</h4>
                          {this.state.creditCardPaymentInfo.amount ? <span>{priceFormat(this.state.creditCardPaymentInfo.amount)}</span> : '-'}
                        </div>
                      </div>
											<div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">Ödeme Tarihi</h4>
                          {this.state.creditCardPaymentInfo.paymentDate ? <span>{isoToDateTime(this.state.creditCardPaymentInfo.paymentDate)}</span> : '-'}
                        </div>
                      </div>
											<div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">İşlemi Yapan</h4>
                          {this.state.creditCardPaymentInfo.addedFullName ? <span>{this.state.creditCardPaymentInfo.addedFullName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
												<div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">Sonuç</h4>
                          {this.state.creditCardPaymentInfo.paymentStatusId === 3 ? <span className="text-danger">Başarısız ({this.state.creditCardPaymentInfo.paymentStatusMessage})</span> : this.state.creditCardPaymentInfo.paymentStatusId !== 2 ? <span className="text-warning">Beklemede</span> : this.state.creditCardPaymentInfo.cancelledBy ? <span className="text-danger">İptal Edildi</span> : <span className="text-green">Başarılı</span>}
                        </div>
                      </div>
                      {this.state.creditCardPaymentInfo.cancelledDate &&
											<div className="col-md-6 mb-2">
												<div className="d-flex flex-column">
                          <h4 className="font-bold text-black mb-0">İptal Tarihi</h4>
                          {this.state.creditCardPaymentInfo.cancelledDate ? <span>{isoToDateTime(this.state.creditCardPaymentInfo.cancelledDate)}</span> : '-'}
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-green">Evraklar</h4>
                    <div className="hr-line-dashed mt-0" />
                    <div className="col-md-12 mb-2 p-0">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0" id='evrak'>Evrak</h4>
                        <form className="d-flex align-items-center justify-content-center dropzone mb-2 mt-2">
                          <div className="fallback">
                            <input accept=".jpg, .jpeg, .png, .pdf" multiple name="file" onChange={(e) => {this.setState({ files: e.target.files}, () => {this.saveFileUpload();});}} type="file"/>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-12 mb-2 p-0">
                      <h4 className="font-bold text-black mb-2">Başvuru Evrakları</h4>
                      {this.state.creditCardPaymentInfo?.files.length !== 0 ?
                        this.state.creditCardPaymentInfo?.files.map((file) => (
                          <div className="file-box" key={file.id}>
                            <div className="file">
                              <div className="d-flex justify-content-end">
                                <button title="Sil" className="btn border mr-1 mt-1" onClick={() => this.deleteFileOnClick(file.id)}>
                                  <i className="fa fa-close"></i>
                                </button>
                              </div>
                              <a title="Görüntüle" target="_blank" href={'https://file.satisgaranti.com/' + file.path}>
                                <span className="corner"></span>
                                <div className="icon">
                                  <i className="fa fa-file"></i>
                                </div>
                                <div className="file-name">
                                  {file.filename} <br /><small className="text-black">{isoToDateTime(file.addedDate)}</small>
                                </div>
                              </a>
                            </div>
                          </div>
                        ))
                      : <div> Evrak Bulunamadı </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CreditCardPaymentDetail() {
  return (
    <CreditCardPaymentDetailModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}